<template>
  <div :key="orderKey">
    <div class="div_title">
      <context :context=cate />
      <policies/>
    </div>
    <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true">
      <div v-if="items.length === 0" class="card main container_no_login no-result" >
        대행 상품을 추가해주세요
      </div>
      <div v-else id="orders">
        <div v-for="(item, index) in items" :key="index">
          <!--  contents.geek9.kr/market -->
          <component v-if="$route.query.list !== undefined" :is="item" ref='key' class="card main" :idx='index+1' :grouped='this.marketOrders[index]' @copy='copy' @remove='remove' /> 
          <!-- 외부에서 유입 -->
          <component v-else-if="$route.query.name !== undefined" :is="item" ref='key' class="card main" :idx='index+1' :route='$route.query' @copy='copy' @remove='remove' />
          <!-- 대행신청메뉴 클릭 -->
          <component v-else-if="$route.params.list === undefined" :is="item" ref='key' class="card main" :idx='index+1' :route='$route.params' @copy='copy' @remove='remove' />
          
          <!-- 카트 -->
          <component v-else :is="item" ref='key' class="card main" :idx='index+1' :route='$route.params.list' :grouped='this.cartOrders[index]' @copy='copy' @remove='remove' /> 
        </div>
      </div>
      <br>
      <div class="div_center">
        <Button @click="add_item()" raised label="&#43; 상품추가" />
      </div>
      <div class="div_title"><span style="color: #ff7f50;">추가옵션</span> <label style="font-weight: normal">배송비 결제시 함께 청구됩니다</label></div>
      <extaraoption ref="extra_option" class="card main" />
      
      <div class="div_title"><span style="color: #ff7f50;">기타요청사항</span> </div>
      <div class="card main" style="text-align: center;">
        <input class="normalInput" ref="etc" type="text" placeholder="상품옵션/사이즈 등" /> 
      </div>
      <div class="div_title" >
        <span style="color: #ff7f50;">받는사람</span> 
        <Button @click='recentInfo()' raised size="small" label="최근배송정보" />
      </div>
      <div class="card main">
        <receiver ref="receiver" :isHelper="isHelper" :orderType="orderType" @update:orderType="orderType = $event" :country="country" @update:country="country = $event" :mail="mail" @update:mail="mail = $event" :phone="phone" @update:phone="phone = $event" :clearance="clearance" @update:clearance="clearance = $event" :orderName="orderName" @update:orderName="orderName = $event" :addrZip="addrZip" @update:addrZip="addrZip = $event" :addrRoad="addrRoad" @update:addrRoad="addrRoad = $event" :addrDetail="addrDetail" @update:addrDetail="addrDetail = $event" />
      </div>
      <div id="agree_section">
        <div class="div_title">
          <span style="color: #ff7f50;">주의사항</span>
          <policies />
        </div>
        <div class="card main">
          <p class="agree">1. GEEK9는 구매자님께서 요청하신 판매자/사이트에 대한 결제와 배송에 대해서만 관여하기 때문에 상품의 진품 여부나 판매자의 사기 여부는 GEEK9도 알 수 없습니다.</p>
          <p class="agree">2. 사기 판매자/사이트, 그외 예상할 수 없는 문제에 대해서 GEEK9는 어떠한 책임도 지지 않습니다.</p>
          <p class="agree">3. 신중한 구매/배송 대행 여부 결정을 부탁드립니다.</p>
          <p class="agree">4. 구매/배송 진행 상황은 위에 입력하신 이메일로 전달되고, 홈페이지에서도 확인 가능합니다.</p>
          <p class="agree">5. 환불/취소를 원하시면 문의 게시판에 환불/취소 의사와 계좌번호를 남겨주시면 됩니다.</p>
          <p class="agree">6. 카톡이나 문자/전화로는 응대를 하지 않습니다. 모든 문의는 문의 게시판에 남겨주시기 바랍니다.</p>
          <div class="agree-wrapper">
            <input id="order-agree-check" ref="chk_agree" class="normal-checkbox" type="checkbox" /> <label for="order-agree-check" class="link">위의 내용을 확인했고, 동의합니다.</label>
          </div>
        </div>
      </div>
      <br>
      <div style="text-align:center;">
        <Button ref="regBtn" @click="regist()" label="주문하기" />
      </div>
    </div>
    <div v-else >
      <login @login="login" /> 
      <br>
      <div class="card main no-login-title">
        <span>대행</span>
        <ul>
          <li><a href="https://geek9.kr/faq?idx=7" target="_blank">비용</a></li>
          <li><a href="https://contents.geek9.kr/blog/57" target="_blank">신청방법</a></li>
          <li>주문과 문의는 홈페이지를 통해서만 가능합니다</li>
        </ul>
        <span class="no-login-title">구매대행</span>
        <ul>
          <li>GEEK9에서 해외 구매/결제와 한국으로의 배송까지 모두 처리</li>
          <li>총비용 = 최초구매시대행비 + 한국으로 보내는 배송비</li>
          <li>최초구매시대행비와 한국으로 보내는 배송비는 따로 청구</li>
        </ul>
        <span class="no-login-title">배송대행</span>
        <ul>
          <li>해외 구매/결제는 고객이, 한국으로의 배송은 GEEK9에서 처리</li>
          <li>총비용 = 한국으로 보내는 배송비</li>
          <li>GEEK9 창고에 물건 도착 시 한국으로 보내는 배송비 청구</li>
        </ul>
        <span class="no-login-title">결제대행</span>
        <ul>
          <li>고객이 알려주는 해외결제정보로 GEEK9에서 결제 처리</li>
        </ul>
      </div>
    </div>
    <div class="ad-container">
      <Adsense
        data-ad-client="ca-pub-6699354447815429" 
        data-ad-slot="8230887469"
        data-ad-format="horizontal"
        data-full-width-responsive="true">
      </Adsense>
    </div>
    <Modal v-model='isShow' :close='closeModal' :setModal='setModal' style="z-index: 99999999999;">
      <div class="modal">
        <span style="color:black; font-weight:bold">최근배송정보</span>
        <div id="sub_menu" style="margin-top:20px;margin-bottom:20px;">
          <div v-for="(order, index) in orders" :key="index" >
            <orderinfoc class="content" @closeModal='closeModal' @setModal='setModal' :idx='index+1' :name='order.ORDER_NAME' :clearance='order.CLEARANCE' :addrZip='order.ADDR_ZIP' :addrRoad='order.ADDR_ROAD' :addrDetail='order.ADDR_DETAIL' :mail='order.MAIL' :phone='order.PHONE'/>
          </div>
        </div>
        <span>
          <Button @click='closeModal()' raised size="small" label="닫기" />
        </span>
      </div>
    </Modal>
  </div>
  </template>
  
  <script>
  import receiver from './components/order/receiver.vue'
  import item from './components/order/item.vue'
  import { defineAsyncComponent, ref } from 'vue'
  import Extaraoption from './components/order/extaraoption.vue'
  import orderinfoc from './components/content/orderinfo_content.vue';
  import moment from 'moment'
  import router from './router'
  import context from '@/components/common/Context.vue'
  import login from '@/components/common/Login.vue'
  import policies from '@/components/common/policies.vue'
  import { inject } from 'vue'
  import ssr from './components/common/ssr'
  
  export default {
    emits: ["setCookies", "setMenu", "login"],
    unmounted() {
      this.emitter.off('orderReload', this.reload)
    },
    mounted() {
      this.emitter.on('orderReload', this.reload)
      this.api.setPage("order")
      this.$emit("setMenu", {page:'order'})
      console.log(this.api.getCookies('page'))
      if(this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true) {
        alert('구매자님께서 요청하신 상품의 판매자가 사기 판매자인 경우에는 해외직구의 특성상 GEEK9에서 어떠한 책임도 지지 않습니다.')
        this.$notify('구매자님께서 요청하신 상품의 판매자가 사기 판매자인 경우에는 해외직구의 특성상 GEEK9에서 어떠한 책임도 지지 않습니다.')
      }
      window.scrollTo(0, 0)
      if(this.$route.query.name !== undefined) {
        this.items = []
        const finalJson = this.$route.query 
        this.country = finalJson.country
        this.cartOrders.push(finalJson)
        this.add_item()
      }
      else if(this.$route.params.list !== undefined) {
        this.items = []
        let cartJson = (this.$route.params.list).replace('[','').replace(']','')
        let split = cartJson.split('},')
        let finalJson = {}
        split.map( (json, index) => {
          if(index !== split.length-1) json += '}'
          finalJson[index] = json
        })
        this.cartOrders = Object.keys(finalJson).map( (key) => {
          let order = JSON.parse(finalJson[key])
          if(order.COUNTRY !== undefined) this.country = order.COUNTRY
          this.add_item()
          return order
        })
      } else if(this.$route.query.list !== undefined) {
        this.items = []
        const json = this.$route.query.list
        const objectRegex = /{[^}]+}/g
        const objects = json.match(objectRegex)
  
        if (!objects) return []
  
        let cnt = 0
        this.marketOrders = objects.map(objStr => {
          const obj = {}
          const pairRegex = /(\w+):([^,}]+)/g
          let match
          while ((match = pairRegex.exec(objStr)) !== null) {
            let [, key, value] = match
            value = value.trim().replace(/^["']|["']$/g, '')
            obj[key] = value
          }
          this.add_item()
          obj.ORDER_NUMBER = ++cnt
          return obj
        })
      }
      this.addJsonLd()
    }, 
    data() { 
      return { 
        orderKey: 0,
        addrZip: '',
        addrRoad: '',
        addrDetail: '',
        orderName: '',
        clearance: '',
        phone: '',
        mail: '',
        country: '',
        orderType: '',
        isHelper:false
      } 
    },
    components: {
      item, receiver, Extaraoption, orderinfoc, policies,
      context, login,
      item_tag: defineAsyncComponent(() => import("./components/order/item.vue"))
    },
    setup() {
      const setMeta = inject('setMeta')
      const title = 'GEEK9 > 대행신청'
      const keywords = '직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,인기상품,해외상품,중고,해외인기상품,vinted,carousell'
      const description = '해외상품의 구매/배송/결제업무는 GEEK9에 맡겨주세요!'
      const geek9Url = ssr.GEEK9_URL + 'order'
      setMeta({
        title: title,
        keywords: keywords,
        description: description,
        'og:title': title,
        'og:description': description,
        'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
        'og:url': geek9Url,
        'canonical': geek9Url
      })
      let items = ref([])
      let cartOrders = ref([])
      let marketOrders = ref([])
      const addJsonLd = () => {
        document.querySelectorAll('script').forEach(el => {
          if (el.getAttribute('type') === 'application/ld+json' ) {
            el.remove()
          }
        })
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify({
          "@context": "https://schema.org",
          "@graph": [
            ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            {
              "@type": "BreadcrumbList",
              "name": "GEEK9 주문할 때 많이 방문하는 페이지",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "GEEK9 > 해외직구도우미",
                "item": "https://geek9.kr/helper",
                "description": "해외 상품의 구매와 배송을 GEEK9에 대행으로 맡기시면 쉽게 해외직구가 가능합니다."
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "GEEK9 > 해외중고샵",
                "item": "https://geek9.kr/shop",
                "description": "유럽과 아시아 중고 상품의 검색과 주문, 결제, 배송업무가 모두 GEEK9에서 가능합니다."
              },{
                "@type": "ListItem",
                "position": 3,
                "name": "GEEK9 > 예상견적",
                "item": "https://geek9.kr/",
                "description": "해외 구매대행과 배송대행의 예상견적을 조회해 보세요."
              },{
                "@type": "ListItem",
                "position": 4,
                "name": "GEEK9 > 문의",
                "item": "https://geek9.kr/qna",
                "description": "개인해외직구에서 기업물류까지 해외업무와 관련된 모든 것에 대해서 답변해 드리겠습니다."
              }]
            }
          ]
        })
        document.head.appendChild(script)
      }
      items.value.push('item_tag')
      const add_item = () => {
        items.value.push('item_tag')
      }
      const isShow = ref(false)
      let orders = ref([])
      async function showModal () {
        isShow.value = true
        const id = this.api.getCookies('id')
        
        await this.api.axiosGql('SELECT_ORDER_INFO_LIST', {type:'SELECT_ORDER_INFO_LIST', id: ""+id})
        .then( (res) => {
          orders.value = res.data
        })
      }
      function closeModal () {
        isShow.value = false
      }
      const cate = ref([
        { label: '대행신청'},
        { label: '구매대행/배송대행', route: '/order' }
      ])
      return {
        add_item, cate, items, closeModal, showModal, orders, isShow, cartOrders, marketOrders, addJsonLd
      }
    },
    methods: {
      setModal(orderInfo) {
        this.$refs.receiver.$refs.name.value = orderInfo.name
        this.$refs.receiver.$refs.clearance.value = orderInfo.clearance
        this.$refs.receiver.$refs.phone.value = orderInfo.phone
        this.$refs.receiver.$refs.mail.value = orderInfo.mail
        this.$refs.receiver.$refs.addr_detail.value = orderInfo.addrDetail
        this.$refs.receiver.$refs.addr_road.value = orderInfo.addrRoad
        this.$refs.receiver.$refs.addr_zip.value = orderInfo.addrZip
      },
      disableBtn(flag) {
        this.$refs.regBtn.disabled = flag
        this.emitter.emit('dialog', {flag:flag, title:'등록중입니다.'})
        // flag === false ? this.$refs.regBtn.style.backgroundColor = '#ffa07a' : this.$refs.regBtn.style.backgroundColor = '#ccc'
      },
      async regist() {
        this.disableBtn(true)
        if(this.$refs.chk_agree.checked) {
          let order_list = document.querySelectorAll("#orderItem .dynamic-table");
          if(order_list.length == 0) {
            alert('대행 상품을 추가해주세요')
            this.disableBtn(false)
            return
          }
          let orderArray = new Array()
  
          const typeTag = this.$refs.receiver.$refs.type
          const countryTag = this.$refs.receiver.$refs.country
          const nameTag = this.$refs.receiver.$refs.name
          const clearanceTag = this.$refs.receiver.$refs.clearance
          const phoneTag = this.$refs.receiver.$refs.phone
          const mailTag = this.$refs.receiver.$refs.mail
          const addrZipTag = this.$refs.receiver.$refs.addr_zip
          const addrRoadTag = this.$refs.receiver.$refs.addr_road
          const addrDetailTag = this.$refs.receiver.$refs.addr_detail
  
          const type = typeTag.value
          const country = countryTag.value
          const name = nameTag.value
          const clearance = clearanceTag.value
          const phone = phoneTag.value
          const mail = mailTag.value
          const addrZip = addrZipTag.value
          const addrRoad = addrRoadTag.value
          const addrDetail = addrDetailTag.value
  
          const opt_tag = this.$refs.extra_option.$refs
  
          let isReceiverValid = true
          if(type === '') {
            alert('대행유형을 선택해주세요')
            typeTag.focus()
            isReceiverValid = false
          } else if(country === '') {
            alert('국가를 선택해주세요')
            countryTag.focus()
            isReceiverValid = false
          } else if(name === '') {
            alert('받는 사람 이름을 입력해주세요')
            nameTag.focus()
            isReceiverValid = false
          } else if(clearance === '') {
            alert('통관부호를 입력해주세요')
            clearanceTag.focus()
            isReceiverValid = false
          } else if(clearance.length !== 13 || (clearance.indexOf('p') === -1 && clearance.indexOf('P') === -1)) {
            alert("통관부호를 정확하게 입력해 주세요");
            clearanceTag.focus()
            isReceiverValid = false
          } else if(phone === '') {
            alert('받는 사람 연락처를 입력해주세요')
            phoneTag.focus()
            isReceiverValid = false
          } else if(mail === '') {
            alert('받는 사람 메일을 입력해주세요')
            mailTag.focus()
            isReceiverValid = false
          } else if(addrZip === '' || addrRoad === '') {
            alert('받는 사람 주소를 입력해주세요')
            addrZipTag.focus()
            isReceiverValid = false
          } else if(country !== '프랑스' && opt_tag.chk_wine.checked === true) {
            alert('와인대행은 프랑스만 가능합니다')
            countryTag.focus()
            isReceiverValid = false
          }
  
          if(isReceiverValid === false) {
            this.disableBtn(false)
            return
          }
          let etc = ''
          let price = 0
          let picturePrice = '0'
          let packagePrice = '0'
          // if(opt_tag.chk_fta.checked === true) etc += '원산지증명 만원,'
          if(opt_tag.chk_picture.checked === true) {
            etc += '사진촬영비용 5000원,'
            picturePrice = '5000'
          }
          if(opt_tag.chk_packing.checked === true) {
            etc += '포장보완비용 5000원,'
            packagePrice = '5000'
          }  
          if(opt_tag.chk_wine.checked === true) etc += '와인 8000원 x 수량,'

          etc += this.$refs.etc.value
          for(var k=0; k < order_list.length; k++) {
            let input_list = order_list[k].querySelectorAll("#orderItem .normalInput");
            let orderJson = new Object()
            for(var j=0; j<input_list.length; j++) {
              const value = input_list[j].value
              if(j === 3 && value > 99999999 ) {
                alert( (k+1) + "번째 상품의 해외 가격이 너무 높습니다.");
                input_list[j].focus()
                window.scrollTo(0, 0)
                this.disableBtn(false)
                return
              }
              else if( '' === value && j !== 2 &&  j !== 6 &&  j !== 7 &&  j !== 8 ) {
                alert( (k+1) + "번째 상품 정보 중 누락된 정보가 있습니다");
                input_list[j].focus()
                window.scrollTo(0, 0)
                this.disableBtn(false)
                return
              } else if(j === 0) {
                if(value.indexOf(',') !== -1) {
                  alert( '상품링크는 하나씩만 입력해야 됩니다. 상품이 여러 개인 경우 "상품추가"를 해서 등록해주시기 바랍니다.')
                  input_list[j].focus()
                  window.scrollTo(0, 0)
                  this.disableBtn(false)
                  return
                }
                if(value.indexOf('carousell') === -1 || value  === -1) orderJson.URL = value 
                else orderJson.URL = this.api.shortenUrl(value)
              }
              else if(j === 1) orderJson.NAME = value
              else if(j === 2) {
                if(value.indexOf(',') !== -1) {
                  alert( '사진링크는 하나씩만 입력해야 됩니다. 상품이 여러 개인 경우 "상품추가"를 해서 등록해주시기 바랍니다.')
                  input_list[j].focus()
                  window.scrollTo(0, 0)
                  this.disableBtn(false)
                  return
                }
                orderJson.IMG_URL = value
              }
              else if(j === 3) orderJson.ORIGIN_PRICE = value
              else if(j === 4) {
                let intAmount = value
                intAmount = intAmount *= 1
                if(intAmount === undefined || intAmount <= 0) {
                  alert('구매수량을 입력해주시기 바랍니다.')
                  input_list[j].focus()
                  window.scrollTo(0, 0)
                  this.disableBtn(false)
                  return
                }
                orderJson.AMOUNT = intAmount
              }
              else if(j === 5) orderJson.ORDER_NUMBER = value
              else if(j === 6) orderJson.TRACKING_NUMBER = value
              else if(j === 7) orderJson.SELL_PRICE = parseInt(value)
              else orderJson.BRAND = value
            }
            orderJson.NOTICE = etc
            orderJson.COUNTRY = country
            orderJson.ORDER_TYPE = type
            orderArray.push(orderJson)
          }
          const loginId = this.api.getCookies('id')
          if(loginId) {
            let orderInfoJson = {type:'INSERT_USER', mail: mail, addrZip: addrZip, addrRoad: addrRoad, addrDetail: addrDetail, name: name, clearance: clearance, phone: phone, id:loginId}
            await this.api.axiosGql('INSERT_USER', orderInfoJson)
            .then( (res) => {
              if(res.data === '') console.log('Already Existed User')
              else if(res.data.NAME === name ) console.log('INSERT_USER SUCCESS')
              else {
                alert('다시 시도해주세요.')  
                this.disableBtn(false)
                return
              }
              orderInfoJson.type = 'INSERT_ORDER_INFO'
              let orderDtlUid = ''
              this.api.axiosGql('INSERT_ORDER_INFO', orderInfoJson)
              .then( async (res) => {
                const uid = res.data.UID
                if(uid !== null ) {
                  orderArray.map(json => { 
                    json.UID = uid
                    orderDtlUid = uid
                    json.ID = loginId
                    if(json.BRAND === undefined || json.BRAND === '') {
                      let split = String(json.URL).split('.')
                      json.BRAND = split[1]
                      if(json.BRAND.indexOf('com') !== -1 || json.BRAND.indexOf('net') !== -1) {
                        json.BRAND = split[0].replace(/http:\/\//g, '').replace(/https:\/\//g, '')
                      }
                    } else if(json.BRAND.length > 15) json.BRAND = json.BRAND.substring(0, 15)
                    json.REG_DATE = moment(new Date()).format('YYYYMMDDhhmm')
                    json.ORDER_DATE = moment(new Date()).format('YYYYMMDD')
                  })
  
                  let paramArray = new Array()
                  for(var i = 0; i < orderArray.length; i++) {
                    const orderJson = orderArray[i]
                    paramArray.push(JSON.stringify(orderJson))
                  }
  
                  const formData = new FormData()
                  formData.append("list", paramArray)
                  formData.append('slack', '접수')
                  formData.append('type', 'INSERT_ORDER_LIST')
  
                  await this.api.axiosGql('INSERT_ORDER_LIST', formData)
                  .then( async (res) => {
                    if(res.data !== undefined ) {
                      this.emitter.emit('dialog', {flag:false})
                      if(price > 0) alert('등록되었습니다. 주문조회를 하시면 진행상황을 확인하실 수 있습니다!!')
                      else alert('등록되었습니다!')
                      router.push(({name:"list"}))
                      
                      
                      
                      
                      
                      
                      // TDOD START : INSERT_ORDER_PRICE_DETAIL 
                      if(!(orderDtlUid === undefined || orderDtlUid === '')) {
                        this.api.axiosGql('INSERT_ORDER_PRICE_DTL', {type:'INSERT_ORDER_PRICE_DTL', uid:orderDtlUid, picturePrice:picturePrice, packagePrice:packagePrice})
                      }
                      // TDOD END : INSERT_ORDER_PRICE_DETAIL

                      
                      
                      
                      
                      
                    } else {
                      alert('다시 시도해주세요.') 
                      this.disableBtn(false)
                      return
                    }
                  })
                  .catch( (err) => {
                    this.disableBtn(false)
                    alert('다시 시도해주세요.')
                    console.log(err)
                  })
                } else {
                  this.disableBtn(false)
                  alert('다시 시도해주세요.')  
                  return
                }
              })
              .catch( (err) => {
                this.disableBtn(false)
                alert('다시 시도해주세요.')
                console.log(err)
              })
            })
            .catch( (err) => {
              this.disableBtn(false)
              alert('다시 시도해주세요.')
              console.log(err)
            }) 
          } else {
            this.disableBtn(false)
            alert('로그인 후 다시 시도해주세요.')
            this.$emit('login')
          }
        } else {
          this.disableBtn(false)
          const el = document.getElementById('agree_section')
          el.setAttribute('class', 'blink')
          alert("주의사항에 동의해주셔야 등록이 가능합니다.")
        }
      },
      async copy(idx) {
        await this.add_item()
        idx--
        let order_list = document.querySelectorAll("#orderItem .dynamic-table")
        let input_list = order_list[idx].querySelectorAll("#orderItem .normalInput")
        const url = input_list[0].value
        const name = input_list[1].value
        const imgUrl = input_list[2].value
        const price = input_list[3].value
        const amount = input_list[4].value
        const orderNumber = input_list[5].value
        const trackingNumber = input_list[6].value
        let wonPrice = ''
        if(input_list[7] !== undefined) wonPrice = input_list[7].value 
        const brand = input_list[8].value
  
        input_list = order_list[order_list.length-1].querySelectorAll("#orderItem .normalInput")
  
        input_list[0].value = url
        input_list[1].value = name
        input_list[2].value = imgUrl
        input_list[3].value = price
        input_list[4].value = amount
        input_list[5].value = orderNumber
        input_list[6].value = trackingNumber
        if(input_list[7] !== undefined) input_list[7].value = wonPrice
        input_list[8].value = brand
      },
      reload(flag) {
        if(flag === 'dashboard') {
          this.orderKey++
          this.$emit("setMenu", {page:'order'})
        }
      },
      recentInfo() {
        if(this.api.getCookies('id')) {
          this.showModal()
        } else {
          alert('로그인 후 다시 시도해주세요.')
          this.$emit('login')
        }
      },
      remove(idx) {
        idx--;
        let order_list = document.querySelectorAll("#orderItem .dynamic-table")
        let order_value_list = []
        for(var i=0; i<order_list.length; i++) {
          if(i !== idx) {
            let input_list = order_list[i].querySelectorAll("#orderItem .normalInput")
  
            const url = input_list[0].value
            const name = input_list[1].value
            const imgUrl = input_list[2].value
            const price = input_list[3].value
            const amount = input_list[4].value
            const orderNumber = input_list[5].value
            const trackingNumber = input_list[6].value
            let wonPrice = ''
            if(input_list[7] !== undefined) wonPrice = input_list[7].value 
            const brand = input_list[8].value
            
            order_value_list[order_value_list.length] = {"url":url,"name":name,"imgUrl":imgUrl, "price":price,"amount":amount,"orderNumber":orderNumber,"trackingNumber":trackingNumber,"wonPrice":wonPrice, "brand":brand}
          }
        }
        this.items.splice(idx, 1)
        order_list = document.querySelectorAll("#orderItem .dynamic-table")
        
        for(var j=0; j<order_value_list.length; j++) {
          let input_list = order_list[j].querySelectorAll("#orderItem .normalInput")
          var json = order_value_list[j]
          input_list[0].value = json.url
          input_list[1].value = json.name
          input_list[2].value = json.imgUrl
          input_list[3].value = json.price
          input_list[4].value = json.amount
          input_list[5].value = json.orderNumber
          input_list[6].value = json.trackingNumber
          if(input_list[7] !== undefined) input_list[7].value = json.wonPrice
          input_list[8].value = json.brand
        }
      },
      login() {
        this.$emit('login')
      }
    }
  }
  </script>